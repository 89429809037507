import React, {useState, useCallback} from 'react'
import { playWaterphone } from '../snd/playSound';

import PlatypusEnter from './PlatypusEnter';
import Platypuss from './Platypuss';

export default function PlatypusContainer() {
  const [didEnter, setDidEnter] = useState(false);
  const onPlatypusEnter = useCallback(evt => {
    evt.preventDefault();
    setDidEnter(true);
    playWaterphone();
  }, []);
  return didEnter ? <Platypuss /> : <PlatypusEnter onClick={onPlatypusEnter} />
}
