import React from "react";
import { Helmet } from "react-helmet";

import platypusBite from "url:../img/platypus-bite.png";
import platypusGuitar from "url:../img/platypus-guitar.png";
import platypus from "url:../img/platypus.png";

const images = [platypus, platypusBite, platypusGuitar];

export default function PlatypusEnter({ onClick }) {
  return (
    <>
      <Helmet>
        {images.map((imageHref) => (
          <link key={imageHref} rel="preload" as="image" href={imageHref} />
        ))}
      </Helmet>
      <header className="header"></header>
      <main className="platypuss">
        <a onClick={onClick} href="#" className="platypus-enter">
          Enter the Platypus
        </a>
      </main>
      <footer></footer>
    </>
  );
}
